<template>
  <v-row
    no-gutters
    class="gestion_des_licences__dashboard-layout"
    :class="{ loading: loading }"
  >
    <v-col cols="12" lg="8" class="mx-auto">
      <v-row style="height: 100%;">
        <v-col cols="12" class="p-4 licences-adherents-content">
          <v-row style="height: 40%;">
            <v-col cols="6">
              <dashboardCard
                module_name="gestion_des_licences__saisir_une_licence_siege"
                title_card="Rajouter une nouvelle licence siège"
                auth
              />
            </v-col>
             <v-col cols="6">
              <!-- <dashboardCard
                module_name="gestion_des_licences__list"
                title_card="Consulter les licences siège"
                auth
              /> -->
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import dashboardCard from "@/components/Common/DashboardCard.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "GestionDesLicences__Dashboard",

  components: { dashboardCard },

  data: () => ({
    loading: true,
  }),
  async created() {
    await this.setAppTitle({ title: "Gestion des licences du Siège" });
    this.loading = false;
  
  },

  methods: {
    ...mapActions("app", ["setAppTitle"]),
  },

  computed: {
    ...mapGetters("user", ["currentUserId"]),
  },
};
</script>

<style lang="scss">
.gestion_des_licences__dashboard-layout {
  position: relative;
  height: calc(100vh - 80px);
  &.loading {
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.8);
    }
  }
}
</style>
