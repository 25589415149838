<template>
  <div>
    <v-row no-gutters>
      <SubBar
        :title="subBarTitle + ' ' + saisonSelected.NomSaison"
        route_name="gestion_des_utilisateurs__dashboard"
      />
    </v-row>
    <SaisirUneLicence
      :context="{
        ID_Structure: currentStructureId,
        ID_Saison: saisonSelected.id,
      }"
    />
  </div>
</template>

<script>
import SubBar from "@/components/Common/Common__SubBar.vue";
import SaisirUneLicence from "@/components/User/Licence/User__SaisirUneLicenceSiege.vue";
import { mapGetters } from "vuex";

export default {
  name: "GestionDesLicences__SaisirUneLicenceSiege",

  components: { SubBar, SaisirUneLicence },

  data: () => ({
    subBarTitle: "Saisir une licence siège",
    mounted: false,
    saisonSelected: null,
    e1: 1,
  }),

  created() {
    // if(this.saisons.find(saison => saison.ID_EtapeSaison == 3)) {
    //   this.selectedSaison = this.saisons.find(saison => saison.ID_EtapeSaison == 3)
    // }else{
    this.saisonSelected = this.selectedSaison;
    // }
    this.mounted = true;
  },
  computed: {
    ...mapGetters("structure", ["currentStructureId"]),
    ...mapGetters("saisons", ["selectedSaison", "saisons"]),
  },
};
</script>
