<template>
  <div>
    <v-row no-gutters>
      <SubBar
        :title="subBarTitle + ' ' + saisonSelected.NomSaison"
        route_name="gestion_des_licences_siege__dashboard"
      />
    </v-row>
     <userList
        :context="context"
        :headers="headers" 
        :items="users"
        hasItems
        ref="userList"
    ></userList>
  </div>
</template>

<script>
import SubBar from "@/components/Common/Common__SubBar.vue";
import { mapActions, mapGetters } from "vuex";
import userList from "@/components/User/List/User__List.vue";

export default {
  name: "GestionDesLicences__SaisirUneLicenceSiege",

  components: { SubBar, userList },

  data: () => ({
    subBarTitle: "Liste des licences siège",
    mounted: false,
    saisonSelected: null,
    e1: 1,
    users:[],
    headers: [
        { text: "Numéro de licence", value: "LicenceNumero" },
        { text: "Prénom", value: "CT_Prenom" },
        { text: "Nom", value: "CT_Nom" },
        { text: "Date de naissance", value: "DN_DateNaissance" },
        { text: "Licence type", value: "ID_LicenceType" },
        // { text: "Saison", value: "ID_LicenceSaison" },
    ],
  }),

  async created() {
    // if(this.saisons.find(saison => saison.ID_EtapeSaison == 3)) {
    //   this.selectedSaison = this.saisons.find(saison => saison.ID_EtapeSaison == 3)
    // }else{
    this.saisonSelected = this.selectedSaison;
    // }
    await this.setData()

    this.mounted = true;
  },

methods: {
    ...mapActions("user", ["getLicenceSiegeUsers"]),
    ...mapActions("utilisateurs", ["getUtilisateur"]),
  async setData() {
    let query = await this.getLicenceSiegeUsers();
    let users =  query.forEach(user => {
      console.log(user)
      let data = {
      UTI_Licence: {
        ID_Saison:user.ID_Saison,
    ID_Structure:user.ID_Structure,
    id:user.id,
    LIST_LicenceType: {
      NomTypeLicence:user.LIST_LicenceType.NomTypeLicence,
      SlugTypeLicence:user.LIST_LicenceType.SlugTypeLicence
    }
      },
      UTI_Utilisateur: user.UTI_Utilisateur
    }
      this.users.push(data)
      console.log(this.users)
    });
  },
},
  computed: {
    ...mapGetters("structure", ["currentStructureId"]),
    ...mapGetters("saisons", ["selectedSaison", "saisons"]),
    context() {
       
        return {
          ID_Structure: this.currentStructureId,
        ID_Saison: this.saisonSelected.id,
        }
      
    }
  },
};
</script>
