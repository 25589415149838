<template>
  <div>
    <v-stepper v-model="etape" alt-labels>
      <v-stepper-header>
        <v-stepper-step :complete="etape > 1" step="1"
          >Rechercher un utilisateur</v-stepper-step
        >
        <v-divider></v-divider>
        <v-stepper-step :complete="etape > 2" step="2"
          >Comparaison</v-stepper-step
        >
        <v-divider></v-divider>
        <v-stepper-step step="3">Confirmation</v-stepper-step>
      </v-stepper-header>

      <v-progress-linear
        v-if="loading"
        indeterminate
        color="cyan"
      ></v-progress-linear>

      <v-stepper-items style="height: calc(100vh - 260px); overflow-y: scroll">
        <v-stepper-content step="1">
          <Etape1
            :context="context"
            isLicenceSiege
            @onNextStep="etape += 1"
            @toggleLoading="loading = !loading"
            @setData="setDataEtape1"
          />
        </v-stepper-content>

        <v-stepper-content step="2" no-gutters>
          <Etape2
            :context="context"
            :searchData="searchData"
            :users="users"
            @onNewUserCreated="onNewUserCreated"
            @onLicenceUpdated="onLicenceUpdated"
            @onNextStep="etape += 1"
            :key="listKey"
          />

          <v-col cols="12" class="text-center">
            <a href="" @click.prevent="backToStep1" class="btn btn-primary"
              >Retour à la recherche</a
            >
          </v-col>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-row v-if="createdUser">
            <v-col cols="12" class="text-center">
              L'utilisateur
              {{ createdUser.CT_Nom + " " + createdUser.CT_Prenom }} a bien été
              crée
            </v-col>
          </v-row>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <modalUserDetail
      v-if="modalUserIsOpen && createdUser"
      :modalIsOpen="modalUserIsOpen"
      :context="{ ...context, fromModal: true }"
      :user="user"
      @onClose="modalUserIsOpen = false"
    />
  </div>
</template>

<script>
import Etape1 from "@/components/User/Licence/User__SaisirLicenceEtape1Siege.vue";
import Etape2 from "@/components/User/Licence/User__SaisirLicenceEtape2Siege.vue";
import modalUserDetail from "@/components/utils/modals/User/User__Modal.vue";

export default {
  components: { Etape1, Etape2, modalUserDetail },

  data: () => ({
    etape: 1,
    loading: false,
    users: [],
    user: {},
    searchData: {},
    listKey: 0,
    createdUser: null,
    modalUserIsOpen: false,
  }),

  methods: {
    setDataEtape1(payload) {
      this.users = payload.users;
      this.searchData = payload.search;
      this.etape += 1;
      this.listKey += 1;
    },

    onNewUserCreated(user) {
      // console.log("user created", user)
      this.createdUser = user;
    },

    backToStep1() {
      this.searchData = {};
      this.etape = 1;
    },

    onLicenceUpdated(user) {
      this.user = {
        ID_Utilisateur: user.ID_Utilisateur,
      };
      this.modalUserIsOpen = true;
    },
  },

  props: {
    context: {
      type: Object,
    },
  },
};
</script>

<style scoped>
.v-stepper__label {
  text-align: center !important;
}
.v-stepper__content {
  padding: 0 !important;
}
</style>
